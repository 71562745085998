<template>
  <div>
    <p class="text-2xl">
      Inserir Saldo
    </p>
    <v-card>
      <v-form autocomplete="off">
        <validation-observer ref="hirePlan">
          <v-card-text class="pa-3">
            <v-row>
              <v-col
                cols="12"
                md="4"
                sm="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Usuário"
                  rules="required"
                  vid="userId"
                >
                  <v-autocomplete
                    v-model="userId"
                    :items="users"
                    :error-messages="errors"
                    :hide-details="errors.length === 0"
                    outlined
                    dense
                    item-text="value"
                    item-value="id"
                    label="Usuário"
                    @change="getSelectedUser"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                md="3"
                sm="6"
              >
                <v-text-field
                  :value="selectedUser.email"
                  disabled
                  outlined
                  dense
                  hide-details
                  label="E-mail"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                sm="6"
              >
                <v-text-field
                  :value="selectedUser.phone"
                  outlined
                  disabled
                  dense
                  hide-details
                  label="Telefone"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                sm="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Valor"
                  rules="required|validate_value:10"
                  vid="wallet.value"
                >
                  <v-text-field
                    v-model="wallet.value"
                    v-money="{
                      precision: 2,
                    }"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    outlined
                    dense
                    prefix="R$"
                    label="Valor (R$)"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-3">
            <v-btn
              color="primary"
              class="pl-4"
              @click="hirePlan"
            >
              Salvar
              <v-icon
                right
                dark
              >
                {{ icons.mdiFileCheck }}
              </v-icon>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="pl-4"
              @click="resetForm"
            >
              Limpar
              <v-icon
                right
                dark
              >
                {{ icons.mdiCancel }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </validation-observer>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mdiFileCheck, mdiCancel } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { mapActions } from 'vuex'

export default {
  setup() {
    const userId = ref(null)
    const users = ref([])
    const selectedUser = ref({})
    const wallet = ref({})

    return {
      users,
      userId,
      selectedUser,
      icons: {
        mdiFileCheck,
        mdiCancel,
      },
      wallet,
    }
  },

  async created() {
    await this.findUsers()
  },

  methods: {
    ...mapActions('user', {
      fetchUsers: 'find',
    }),
    ...mapActions('wallet', [
      'create',
    ]),

    resetForm() {
      this.userId = null
      this.selectedPlan = {}
      this.selectedUser = {}
    },

    getSelectedUser() {
      const user = this.users.find(user => user.id === this.userId)

      if (user) {
        this.selectedUser = user
      }
    },

    async findUsers() {
      const users = await this.fetchUsers()

      this.users = users.map(user => ({
        ...user,
        value: `${user.document ? String(this.$cpfCnpj.toMask(user.document)).concat(' - ', user.name) : user.name}`,
        phone: `${this.$phone.toMask(user.phone ?? '')}`,
      }))
    },

    async hirePlan() {
      if (await this.$refs.hirePlan.validate()) {
        const status = await this.create({
          userId: this.userId,
          value: this.$strip(this.wallet.value),
        })

        if (status === 201) {
          this.resetForm()
        }
      }
    },
  },
}
</script>
